<template>
    <section class="config-tipo-anuncios ml-4">
        <cargando v-if="cargando" />
        <div class="row">
            <div class="col">
                <div class="row mb-4">
                    <div class="col-auto col-sm-6 col-md-7 col-lg-6 col-xl-7">
                        <p class="f-20 f-500">Tipos de anuncios</p>
                    </div>
                    <div class="col-4">
                        <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                            <div slot="content" class="tooltip-editar-residente">
                                <div class="d-flex mb-2 editar-opcion py-1 px-1 cr-pointer br-4" @click="crearAnuncioNuevo">
                                    <i class="icon-circle-add mr-2 f-17" />
                                    <p class="f-15">Tipo nuevo</p>
                                </div>
                                <div class="d-flex my-auto editar-opcion py-1 px-1 cr-pointer br-4" @click="crearAnuncioBasadoExistente">
                                    <i class="icon-pencil-outline my-auto mr-2 f-17" />
                                    <p class="f-15">A partir de un existente</p>
                                </div>
                            </div>
                            <button class="btn btn-general f-14 f-300 pl-4">
                                <span>
                                    Nuevo tipo
                                    <i class="icon-down-open ml-2" />
                                </span>
                            </button>
                        </el-tooltip>
                    </div>
                </div>
                <div v-for="(tipo,i) in tipos" :key="i" class="row mb-3">
                    <div class="col-auto col-sm-6 col-md-7 col-lg-6 col-xl-7 cr-pointer" @click="$router.push({name:'configurar.editar.anuncio', params:{id_tipo:tipo.id}})">
                        <div class="d-flex my-auto">
                            <div class="circle-icon rounded-circle d-middle-center" :style="`background: ${tipo.color ? tipo.color : '#dbdbdb'}`">
                                <i :class="`f-15 op-05 ${tipo.icono ? tipo.icono : ''}`" />
                            </div>
                            <p class="f-15 my-auto ml-2"> {{ tipo.nombre }} </p>
                        </div>
                    </div>
                    <div class="col-auto d-flex">
                        <div style="width:85px">
                            <div class="d-middle op-05 br-20 bg-f5 h-21">
                                <i class="icon-calendar f-13" />
                                <p class="f-12 ml-1">{{ tipo.vigencia }} Días</p>
                            </div>
                        </div>
                        <div style="width:85px">
                            <div class="d-middle d-middle-center br-20 bg-f5 px-2 h-21 ml-2">
                                <p class="f-12">{{ tipo.cantUsos }} Usos</p>
                            </div>
                        </div>
                        <div style="width:80px">
                            <div v-if="tipo.dinero" class="d-middle d-middle-center br-20 bg-f5 f-12 ml-2 px-2 h-21">
                                <p>Tipo valor</p>
                            </div>
                        </div>
                    </div>
                </div>      
            </div>
        </div>
        <!-- Partials -->
        <modal-crear-basado-existente ref="modalCrearBasadoExistente" />
    </section>
</template>
<script>
import Categorias from '~/services/carteleras/categorias'
export default {
    components:{
        modalCrearBasadoExistente: () => import('./partials/modalCrearBasadoExistente')
    },
    data(){
        return{
            tipos:[],
            cargando: false
        }
    },
    created(){
      this.listar()
    },
    methods:{
        aceptarSolicitud(){
            this.$refs.modalCrearBasadoExistente.toggle()
        },
        crearAnuncioNuevo(){
            this.$router.push({ name:'configurar.guardar.anuncio' })
        },
        crearAnuncioBasadoExistente(){
            this.$refs.modalCrearBasadoExistente.toggle(this.tipos)
        },
        async listar(){
          try {
            this.cargando = true
            let params={
              caso: 1
            }
            const {data} = await Categorias.listar(params)
            this.tipos = data.data
        } catch (e){
            this.errorCatch(e)
        } finally {
            this.cargando = false
        }       
        },
    }
}
</script>
<style lang="scss" scoped>
.config-tipo-anuncios{
    .btn-general{
        height: 32px;
    }
    .circle-icon{
        min-width: 32px;
        min-height: 32px;
        max-width: 32px;
        max-height: 32px;
    }
    .h-21{
        max-height: 22px;
        min-height: 22px;
    }
    i.icon-book{
        &::before{
            margin-left: 0;
        }
    }
}
</style>